import axios, { AxiosResponse, Canceler } from 'axios';
import { TApi } from 'libs/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import useHandleHttpError from './useHandleHttpError';

interface IUseFetchResourceParams<T> {
  api: TApi;
  initialValues: T;
  initialLoad?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  initialParams?: object;
  serializer?: (data: { resource: T; resources: T }) => any;
  useNotFound?: boolean;
}

interface IState<T> {
  resource: T;
  isLoading: boolean;
}

export default function useFetchResource<T>({
  api,
  initialValues,
  initialLoad = true,
  initialParams,
  serializer = (data: { resource: T }): T => data.resource,
  useNotFound = false,
}: IUseFetchResourceParams<T>): IState<T> & {
  // eslint-disable-next-line @typescript-eslint/ban-types
  fetchResource: (params?: object) => Promise<T>;
  cancelFetch: () => void;
  setResource: (resource: T | ((resource: T) => T)) => void;
} {
  const handleHttpError = useHandleHttpError();
  const cancel = useRef<Canceler | null>(null);
  const [{ resource, isLoading }, setValues] = useState<IState<T>>({
    isLoading: initialLoad,
    resource: initialValues,
  });

  const fetchResource = useCallback(
    async (params) => {
      try {
        setValues((state) => ({ ...state, isLoading: true }));
        const response: AxiosResponse<{ resource: T; resources: T }> = await api({
          ...params,
          params,
          // Can't import CancelToken as
          // import { CancelToken } from 'axios';
          // Because in this case CancelToken is interface but need class.
          cancelToken: new axios.CancelToken((cancelHandler) => {
            cancel.current = cancelHandler;
          }),
        });
        const resource = serializer(response.data);
        setValues({ resource, isLoading: false });
        return resource;
      } catch (error) {
        // Can't import Cancel as
        // import { Cancel } from 'axios';
        // Because in this case Cancel is interface but need class.
        if (!(error instanceof axios.Cancel)) {
          setValues((state) => ({ ...state, isLoading: false }));
        }
        handleHttpError(error, undefined, useNotFound);
      }
    },
    [api]
  );

  const cancelFetch = useCallback(() => {
    if (cancel.current !== null) {
      cancel.current();
    }
  }, [cancel]);

  const setResource = useCallback((setter) => {
    setValues((state) => ({
      ...state,
      resource: typeof setter === 'function' ? setter(state.resource) : setter,
    }));
  }, []);

  useEffect(() => {
    if (initialLoad) {
      fetchResource(initialParams);
    }
  }, []);

  useEffect(() => {
    return () => {
      cancelFetch();
    };
  }, [cancelFetch]);
  return { resource, isLoading, fetchResource, cancelFetch, setResource };
}
